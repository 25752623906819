:root {
  --slick-pink: #f0c;
  --slick-pink-transparent: rgba(255,0,204,0.15);
}

.index-and-details {
  --page-controls-height: 5rem;
  --background-color: #fff;
  --text-color: #222;

  background-color: var(--background-color);
  color: var(--text-color);
}

html, body, #root {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  cursor: pointer;
  color: #2E56A6;
  text-decoration: none !important;
}

p a, li a {
  border-bottom: 1px dotted #2551B8;
}

p, ul, ol {
  margin: 0 0.5em 0 0;
  line-height: 1.6em;
  font-weight: lighter;

  &:not(:last-child) {
    margin-bottom: 1.6em;
  }
}

blockquote {
  letter-spacing: -0.04em;
  font-weight: 200;
  font-size: 1.7em;
  line-height: 1.3em;
  margin: 0 0 0 1em;
  border-left: 0.35rem solid #ccc;
  padding: 0.2em 1em 0.2em 0.5em;
}

blockquote:before {
  font-family: serif;
  color: #ccc;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.15em;
  vertical-align: -0.4em;
}

blockquote:after {
  content: no-close-quote;
}

ul, ol {
  padding-left: 1.8em;
}

li {
  line-height: 1.4em;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

h1, h2, h3, header {
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;

  &:empty {
    display: none;
  }
}

* + h1, * + h2, * + h3, * + h4 {
  margin-top: 1.2em;
}

h1 {
  font-size: 2.2em;
  font-weight: lighter;
  line-height: 0.95em;
}

h1 + p, h3 + p {
  margin-top: 0.8em;
}

h2 {
  font-weight: 200;
  font-size: 1.5em;
  margin-bottom: 0.4em;
}

h3 {
  font-weight: 400;
}

label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.72rem;
  color: #444;
}

input {
  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 0.8em;
    text-overflow: ellipsis;
  }
}

input, textarea {
  &::placeholder {
    color: var(--text-color);
    opacity: 0.3;
  }
}

button {
  border: none;
  flex: 1;
  background-color: #444;
  color: #fff;
  cursor: pointer;
  padding: 0.6rem 1rem 0.6rem;
  font-size: 1.5rem;
  font-weight: 200;
  font-family: sans-serif;
  display: block;
  line-height: 1.2em;
  transition: all 0.15s ease;

  &:disabled {
    cursor: not-allowed;
    background-color: #eee;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: var(--slick-pink);
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }
}

.flex {
  &.row {
    display: flex;
    &.center {
      align-items: center;
    }
    > *:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}

.link {
  cursor: pointer;
}

#root {
  &.explain {
    transform: scale(0.3);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    &:after {
      content: '';
      position: absolute;
      top: -10%;
      left: 0;
      right: 0;
      bottom: -10%;
      z-index: -1;
      outline: 2px solid #66d;
    }

    .index-and-details {
      outline: 1px solid red;
    }
  }
}

@media (max-width: 50rem) {
  .hideOnTablet {
    display: none;
  }
}

@media (max-width: 30rem) {
  .hideOnPhone {
    display: none;
  }
}
